import React, { useState, useEffect } from "react";
import client from "../../client.js";
import { useParams } from "react-router-dom";

export default function Signin({xxx0, xxx1}) {
  const [passData, setPassData] = useState(null);
  const [userData, setUserData] = useState({ password: "" });
  const [errorMessage, setErrorMessage] = useState({ value: "" });
  const [pawData, setPawData] = useState(null);
  // const { slug } = useParams();

const query = '*[_type == "files"]{"xxx": pkey,  "manuscriptURL": manuscript.asset->url}';

  useEffect(() => {
    client.fetch(
        query)
       .then((data) => setPassData(data))
      // .then((punk) => console.log("nono" + passData))
     
      .catch(console.error());
  }, []);


  

  // useEffect(() => {
  //   const paliux = JSON.stringify(passData);
  //   const resultado = paliux.indexOf("slug");
  //   console.log("1 Es " + resultado);
  //   const resultx = paliux.slice(resultado, -1);
  //   console.log("2 Es " + resultx);
  //   const resultado0 = resultx.slice(6,-1)
  //   console.log("3 Es " + resultado0);

  //   setPawData(resultx);
  //   console.log("ganja " + "over");
  //   // console.log(passData);
  //   // console.log("yonu");
  // },[0]);



  // useEffect(() => {
  //   let resultw = JSON.stringify(passData);
  //   const yozup = resultw.slice(35, -20);
  //   console.log("ganjamm");
  //   console.log("final " + yozup);
  //   // console.log(passData.xxx);
  // });

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //if username or password field is empty, return error message
    if (
      // userData.username === "" || 
      
      userData.password === "") {
      setErrorMessage((prevState) => ({
        value: "Empty username/password field",
      }));
    } else if (
      // userData.username.toLowerCase() === "admin" &&
      userData.password === xxx0
    ) {
      //Signin Success
      localStorage.setItem("isAuthenticated", "true");
      window.location =  xxx1 ;
      console.log("successss");
    } else {
      //If credentials entered is invalid
      setErrorMessage((prevState) => ({ value: "Invalid username/password" }));
      return errorMessage;
    }
  };

  return (
    <>
      <div className="text-center">
        {/* <h1>Signin User</h1> */}
        {/* <h1>{passData}</h1> */}
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <div className="form-group">
            {" "}
            <label>Username</label>
            <input
              className="form-control"
              type="text"
              name="username"
              onChange={(e) => handleInputChange(e)}
            />
          </div> */}
          <div className="form-group " style={{display:"flex", width:"30%"}}>
            {/* <label>Password</label>{" "} */}
            <input
              className="form-control"
              maxLength="8"
              type="password"
              name="password"
              placeholder="Password"
              onChange={(e) => handleInputChange(e)}
            />
             <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            {" "}
            Submit{" "}
          </button>
          </div>
         
        </form>
      </div>
    </>
  );
}
