import React, { useEffect } from "react";
// import GetRequest from "./GetRequest";
import Navbar from "./Navbar";
import Post from "./Post";
import { Counter } from "../features/counter/Counter";

export function Talent(props) {

  document.title = props.title;

  return (
    <>
      <div id="one-talent" className="TeamTinchy animated bounceInRight"></div>
      <div id="two" className="TeamTinchy animated bounceInRight">
        <Navbar />
      </div>
      <div id="three" className="TeamTitchy animated bounceInRight">
      <img
  className="panealogo girar spinhov "
  src="https://s3.us-east-2.amazonaws.com/www.phy-sis.xyz/Panea-01.svg"
  alt="Panea"
  style={{float: "right",
    marginRight: "4.5rem",
    marginTop: "5rem",
  }}
/>


        <Post />
        {/* <Counter/> */}

        <span className="copyright">WAMA ENTERTAINMENT INC.  &copy; 2023</span>

      </div>
    </>
  );
}

export default Talent;
