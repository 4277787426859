import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import UserList from './components/Characters';
// import GetRequest from './components/GetRequest';
import Main from "./components/Main";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Talent from "./components/Talent";
import Contact from "./components/Contact";
import Mediax  from "./view/Home/index.js";
import Signin from "./view/Authentication/Signin";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <div
    //  style={{paddingTop:"1rem"}} 
     >
 <Router>
      <Routes>
        <Route
          caseSensitive={false}
          exact
          path="/"
          element={<Main title="PANEA" />}
        />

        {/* <Route
          caseSensitive={false}
          exact
          path="/mediax"
          title="Media"
          element={<ProtectedRoute />}
        >
          <Route
            caseSensitive={false}
            element={<Mediax title="Media" />}
            exact
            path="/mediax"
          />
        </Route> */}

        <Route
          caseSensitive={false}
          exact
          path="/media"
          title="Media"
          element={<Mediax title="Media n" />}
       />
          


        <Route
          caseSensitive={false}
          element={<Talent title="Talent" />}
          path="/talent"
        />
        <Route
          caseSensitive={false}
          element={<Signin title="SIGNIN" />}
          path="/signin"
        />

        {/* <Route caseSensitive={false} element={<SinglePost />} path='/post/:slug' />  */}
        {/* <Route caseSensitive={false} element={<Project />} path='/project' /> */}
        <Route
          caseSensitive={false}
          element={<Contact title="Contact" />}
          path="/contact"
        />
      </Routes>
    </Router>
    </div>
   
    // <div className="App">
    //   <Main/>
    //   <UserList/>
    //  <GetRequest/>

    // </div>
  );
}

export default App;
