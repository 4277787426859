import { useDispatch } from "react-redux"; 
import { useEffect } from "react"; 
import { getAllPosts } from "../../actions/TestAction"; 
import { Media } from "../../components/Media";
// import Users from "../../components/Users"; 
import "../../App.css";

function Mediax() {
const dispatch = useDispatch();

useEffect(() => {
dispatch(getAllPosts()); 

}, []);

// const handleLogout = () => {
//     localStorage.clear();
//     window.location.pathname = "/signin";
// }

return <div className="app">
<Media  title="Media" />
{/* <button onClick={handleLogout}>OUtie</button> */}

 </div>

}
export default Mediax;