import React from "react";
import Files from "./Files";
import Navbar from "./Navbar";

export const Media = (props) => {
  document.title = props.title;

  return (
    <>
      <div id="one" className="TeamTinchy animated bounceInRight"></div>

      <div id="two" className="TeamTinchy animated bounceInRight">
        <Navbar />
      </div>

      <div id="three" className="TeamTitchy animated bounceInRight">
        <img
          className="panealogo girar spinhov "
          src="https://s3.us-east-2.amazonaws.com/www.phy-sis.xyz/Panea-01.svg"
          alt="Panea"
          style={{ float: "right", marginRight: "4.5rem", marginTop: "5rem" }}
        />
        <Files title="Media" />
        <span className="copyright">
        WAMA ENTERTAINMENT INC.  &copy; 2023
        </span>
      </div>
    </>
  );
};
