import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import client from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { Modal, ModalBody } from "reactstrap";




export default function Post() {

  const [postData, setPost] = useState(null);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const query = '*[_type == "post"] | order(_createdAt asc) {title, slug, imdb, body, instagram, mainImage{asset->{_id,url},alt}}'


  useEffect(() => {
   client
      .fetch(query)
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  const toggle = (post) => {
    setSelected(post);
    setModal(true);
  };

  const closeModal = () => {
    setSelected(null);
    setModal(false);
  };

  return (
    <main className="fondo min-h-screen p-12" style={{paddingTop: '6rem'}}>
      <section className=" animate__animated animate__fadeIn container mx-auto">
        <span className="text-5xl flex justify-center cursive head-page">
          {document.title}
        </span>
     
        {/* <h2 className="text-lg text-gray-600 flex justify-center mb-12"></h2> */}
        <div className="grid flex md:grid-cols-2 lg:grid-cols-3 gap-8" style={{display:"flex", flexWrap:'wrap', alignItems:"flex-start", margin:".5rem", width: "30% !important"}}>
          {postData &&
            postData.map((post, index) => (
              <article key={index} className="fondo" style={{margin: '0.5rem'}}  onClick={() => toggle(post)}>
                {/* <Link to={"/post/" + post.slug.current} key={post.slug.current}> */}
                <span
                  className="fondo block h-64 relative rounded shadow leading-snug   borderg-green-400"
                  key={index}
                >
                  <img
                    src={post.mainImage.asset.url}
                    alt={post.mainImage.alt}
                    className="w-full h-full rounded-r object-cover absolute"
                    style={{ width: '18vw',     height: "25vw", objectFit: "cover"}}
                    
                  />
                  <span className="block relative h-full flex justify-end items-end  pb-4">
                    <h3 className=" text-lg font-blog  py-1  bg-opacity-0 rounder" style={{ wordWrap: "break-word", width: '18vw'}}>
                      {post.title}
                    </h3>
                   
                  </span>
                </span>
                {/* </Link> */}
              </article>
            ))}
                   
          <Modal
            className="modalx"
            isOpen={modal}
            fullscreen
            size="xl"
            // modalTransition={{ timeout: 100 }}
            data-keyboard="false"
          >
            <ModalBody>
              <div className="row">
                <div className="col" style={{ columnWidth: '45%'}}>
                <img
               style={{ width: "38vw",
                height: "45vw", objectFit: "cover"}}
                src={selected && selected.mainImage.asset.url}
                alt=""
              />
                </div>
                <div className="col" style={{ columnWidth: '55%'}}> 
                <button onClick={closeModal} style={{float:"right"}}>X</button>
                <h1 style={{paddingTop:"7rem", paddingBottom:"2rem"}}>  {selected && selected.title} </h1>

                {/* // serializers={{types: {block: BlockRenderer}}} */}
<div className="contenido-modal-talent">
<BlockContent 
            
            blocks= {selected && selected.body}
            projectId="r46wegb6"
            dataset="production"
          />
</div>
             
             <button href={selected && selected.imdb}> IMDB</button>
             &nbsp;
              <button href={selected && selected.instagram}> INSTAGRAM</button>
                 
         
               </div>
              </div>
             
            
            </ModalBody>
          </Modal>
        </div>
        {/* <div className="modal-backdrop" onClick={closeModal}></div> */}
        <div className="logo1 ">

</div>
      </section>
    </main>
  );
}
