import React, { useState, useEffect } from "react";
import client from "../client.js";
import { Modal, ModalBody } from "reactstrap";
import Signin from "../view/Authentication/Signin.js";

export default function Files() {
  const [postDatax, setPostx] = useState(null);
  // const [passData, setPassData] = useState(null)
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectId, setSelectId] = useState({
    _id: null,
  });

  const query = ' '

  useEffect(() => {client.fetch(
        `*[_type == "files"] | order(_createdAt asc) 
        {"xxx":pkey,
        _id,
        title,
        typeproject,
        year,
        mainImage{
        asset->{
                _id,
                url
            },
            alt
        },
        "manuscriptURL": manuscript.asset->url
    } `
      )
      .then((data) => setPostx(data))
      // .then(console.log("ayo" + `${postDatax}`))
      .catch(console.error);
  }, []);

  const toggle = (post) => {
    setSelected(post);
    setModal(true);
    setSelectId({
      ...selectId,
      _id: ""
    });
 
  //  const ayo = JSON.stringify(post);
  //   console.log("yikes" + ayo);
  //  const aver = ayo.indexOf("xxx");
  //  console.log("yookes" + aver);
  //  const resolto = ayo.slice(aver, -2);
  //  const finale = resolto.slice(6,-14);
  //  console.log("yuukes " + finale );
  //  setPassData(finale);
  };

  const closeModal = () => {
    setSelected(null);
    setModal(false);
    
  };

  return (
    <main className="fondo min-h-screen p-12 " style={{ paddingTop: "6rem" }}>
      <section className=" animate__animated animate__fadeIn container mx-auto">
        <span className="text-5xl flex justify-center cursive head-page">
          {document.title}
        </span>

        <div
          className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 "
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >






          {postDatax &&
            postDatax.map((post, index, _id) => (
              <article
                key={index}
                className="fondo"
                onClick={() => toggle(post)}
              >
                <span
                  className="fondo block h-64 relative rounded  leading-snug  px-3 mr-3 ml-3 borderg-green-400"
                  key={index}
                >
                  <img
                    src={post.mainImage.asset.url}
                    alt={post.mainImage.alt}
                    className="w-full h-full rounded-r object-cover absolute"
                    style={{ width: "18vw" }}
                  />
                  <span className="block relative h-full flex justify-end items-end pr-4 pb-4">
                    <h3 className=" text-lg font-blog px-3 py-4  bg-opacity-0 rounder">
                      {post.title}
                      <br />
                    </h3>
                   
                  </span>
                </span>
                <div
         
          >
            <Modal
             key={post._id}
              isOpen={modal}
              id=""
              fullscreen
              size="xl"
              contentClassName="custom-modal-stylex"
              // toggle={toggle}
              // modalTransition={{ timeout: 100 }}
            >
              <ModalBody
                style={{
                  backgroundColor: "#3563C4",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  display: "block",
                  margin: "0 auto",
                }}
              >
                <div style={{ width: "30%" }}></div>
                <button
                  style={{
                    display: "flex",
                    float: "right",
                    position: "relative",
                  }}
                  onClick={closeModal}
                >
                  X
                </button>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1
                  className="center align-middle"
                  style={{
                    display: "block",
                    alignItems: "center",
                    width: "30%",
                    margin: "0 auto",
                    
                  }}
                >
                  {selected && selected.title}
                  <hr />
                </h1>
                <div
                  className="center align-middle"
                  style={{
                    display: "block",
                    textAlign: "center",
                    alignItems: "center",
                    width: "40%",
                    margin: "0 auto",
                  }}
                >
                  <div className="row">
                    <div className="col">
                      <h5>{selected && selected.typeproject}</h5>{" "}
                    </div>

                    <div className="col">
                      <h5>{selected && selected.year}</h5>
                    </div>
                    <br />
                  </div>
                  <hr
                    style={{ width: "75%", margin: "0 auto", display: "block" }}
                  />
                </div>
                <br /> <br />
                <Signin
                xxx0={selected && selected.xxx}
                xxx1={selected && selected.manuscriptURL}
                style={{ textAlign: "center", alignItems: "center" }} />

                <div>
                
     
              {/* <a
                className="salida"
                style={{
                  textAlign: "center",
                  display: "block",
                  margin: "0 auto",
                }}
                // href={`${selected && selected.manuscriptURL}?dl=`}
                href={` ${ JSON.stringify(selected && selected.slug)}`}

              >
                Download Files
              </a>  */}
</div>

                <br /> <br />
                <br/> <br/>
              </ModalBody>
            </Modal>
          </div>
              </article>
              
            ))}











         
        </div>
        {/* <span className="copyright">PANEA TALENT &#38; MEDIA AGENCY &copy; 2022</span> */}
      </section>
    </main>
  );
}
