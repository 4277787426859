import React from 'react'

function Navbar() {
  return (
    <div className="texto fadex">
    <div>
    

      <a href="/">
              <img
                className="Logo"
                src="https://s3.us-east-2.amazonaws.com/www.phy-sis.xyz/Panea-02.svg"
                alt="Panea Logo"
              />
              </a>
    </div>
    <br/><br/>
    {/* <h1 className="head-title">
      TALENT MANAGEMENT
      <br />
      &amp; CONTENT DEVELOPMENT
    </h1> */}
    {/*<a className="navs" rel="noopener noreferrer" href="/media">
      <button className="boton boton-m">MEDIA</button>
  </a> */}
   
<br/>
    <a className="navs" rel="noopener noreferrer" href="/talent">
      <button className="boton boton-t">TALENT</button>
    </a>
    <br/>
  
    <a className="navs" rel="noopener noreferrer" href="/contact">
      <button className="boton boton-c">CONTACT</button>
    </a>
  </div>
  )
}

export default Navbar


