import React from "react";
import Navbar from "./Navbar";

export default class Main extends React.Component {
  render() {
    return (
      <>
        <div id="one-main" className="TeamTinchy animated bounceInRight"></div>
        <div id="two" className="TeamTinchy animated bounceInRight">
      <Navbar/>
        </div>

        <div id="three" className="TeamTitchy animated bounceInRight">
        <main className="fondo min-h-screen p-12" style={{paddingTop: '5rem'}}>
      <section className=" animate__animated animate__fadeIn container mx-auto">
        <span className="text-5xl flex justify-center cursive head-page">

<div className="logo1 ">
<img
  className="panealogo girar spinhov "
  src="https://s3.us-east-2.amazonaws.com/www.phy-sis.xyz/Panea-01.svg"
  alt="Panea"
/>
</div>
<span className="copyright2">WAMA ENTERTAINMENT INC.  &copy; 2023</span>
        </span>
        </section>
        </main>
        </div>
      </>
    );
  }
}
