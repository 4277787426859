import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import Navbar from "./Navbar";

export default function Contact(props) {
  const [postD, setPos] = useState();
  document.title = props.title;


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "mail"] { 
         mailmedia,
         mailtalent
    }`
      )
      .then(() => setPos())
    .then(console.log("yes" + postD))
    
      .catch(console.error);
  }, []);

  
  // const mailo = postD.mailmedia;

  // useEffect(() =>{
  //   alert(postD.mailmedia)
  // },[]);

  return (
    <>
      <div id="one-contact" className="TeamTinchy animated bounceInRight"></div>

      <div id="two" className="TeamTinchy animated bounceInRight">
        <Navbar />
      </div>

      <div id="three" className="TeamTitchy animated bounceInRight">
        <img
          className="panealogo girar spinhov "
          src="https://s3.us-east-2.amazonaws.com/www.phy-sis.xyz/Panea-01.svg"
          alt="Panea"
          style={{ float: "right", marginRight: "4.5rem", marginTop: "5rem" }}
        />

        <main
          className="fondo min-h-screen p-12"
          style={{ paddingTop: "7rem" }}
        >
          <section className=" animate__animated animate__fadeIn container mx-auto">
            <span className="text-5xl flex justify-center cursive head-page">
              {document.title}
            </span>
            <br/>
            <br />
            <div>
              <h3>
                MEDIA
                <br />
              </h3>
              <a
                className="mailto"
                rel="noopener noreferrer"
                href="mailto{}"
              >
                <button >cmezayazpik@panea.media</button>
              </a>
              <br/>
              <br/>
       
              <h3>
                TALENT
              
              </h3>

              <a
                className="mailto"
                rel="noopener noreferrer"
                href="mailto:cmezayazpik@panea.media"
              >
                <button >cmezayazpik@panea.media</button>
              </a>
                 <br />
              <br />
              <h3>
                ADDRESS
                <br />
              </h3>
              {/* <a
                className="mailto"
                rel="noopener noreferrer"
                href="mailto{}"SZ
              > */}
                <span >925 B Street - Suite 401k</span>
                <br/>
                <span >San Diego Californai, 92101 USA</span>
              {/* </a> */}
           
            </div>
          </section>
        </main>

        <span className="copyright2">WAMA ENTERTAINMENT INC.  &copy; 2023</span>

      </div>
    </>
  );
};

